html{
  background-color: #f4f4f5;
}

body {
  margin: 0;
  background-color: #FFF5DD;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter, sans-serif;
  font-feature-settings: "salt", "zero", "liga", "ss01";
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
