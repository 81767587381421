body {
  background: rgb(244, 244, 245);
  color: rgb(25, 25, 46);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Inter, sans-serif;
  font-feature-settings: "salt", "zero", "liga", "ss01";
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;    
}

@import '~antd/dist/antd.css';

.App {
  overflow: hidden;
  text-align: center;
}

.App-logo {
  height: 450px;
  pointer-events: none;
}

.App-logo2 {
  height: 250px;
  position: absolute;
  top:20px;
  pointer-events: none;
}
.logo{
  height: 32px;
  margin-right: 10px;
}

#logo{
  display: flex;
  align-items: center;
  padding: 0 25px;
  color: #ffc146;
  justify-content: space-between;
  height: min-content;
  border-bottom: 1px solid rgba(25, 25, 46, 0.04);
  width: 100%;
  height: 64px;
}

.light{
  background-color: #fff;
}

.App-header {
  background-color: #FFF5DD;
  /* background-color: #18111D; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header5 {
  background-color: #FFF5DD;
  /* background-color: #18111D; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.header{
  display: flex;
  justify-content: space-between;
  transition: all 0.2s;
  border-bottom: 1px solid rgba(25, 25, 46, 0.04);
  background: #f4f4f5;
}

.df-order{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #b7b7b7;
  width: 24px;
  margin-right:10px;
}

.footer{
  opacity: 0.85;
  font-size: 13px;
  border-top: 1px solid rgba(25, 25, 46, 0.04);
}

.ant-layout-sider-children {
  border-right: 1px solid rgba(25, 25, 46, 0.04);
}

.ant-menu-sub.ant-menu-inline{
  background-color: #efeff5;
}

.ant-layout-footer {
  background: #f4f4f5;
}

.ant-layout-sider-zero-width-trigger {
  background: #5a3f96;
}

.ant-btn-primary {
  border-radius: 4px;
}

.ant-card {
  border-radius: 6px;
}

.ant-card-head {
    border-radius: 6px 6px 0 0;
}

.ant-tag{
  border-radius: 5px;
  border:1px solid #e4e4e4;
}

.ant-upload-list-picture-card-container{
  width:52px;
  height:52px;
  border:none;
}
.ant-upload.ant-upload-select-picture-card{
  height:52px;
  width:52px;
}
.anticon-eye{
  display: none;
}

.ant-tabs-nav {
  width: 190px;
}
/* 
.ant-tabs-nav-list{
  height: 60vh;
  overflow-x: auto;
} */

.ant-tabs-tab-btn{
  text-transform: capitalize;
}

.App-header2 {
  background-color: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header3{
  background-color:  #f4f4f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#breathing-button {
  -webkit-animation: breathing 5s ease-out infinite normal;
  animation: breathing 5s ease-out infinite normal; 
}


@-webkit-keyframes breathing {
0% {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

25% {
  -webkit-transform: scale(1);
  transform: scale(1);
}

35% {
  -webkit-transform: scale(1);
  transform: scale(1);
}

85% {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

100% {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
}

@keyframes breathing {
0% {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

25% {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}


35% {
  -webkit-transform: scale(1);
  transform: scale(1);
}

85% {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

100% {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}
}


.df-card{
  width: 100%;
  transform: translateZ(0px);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.16, 0.64, 0.48, 1) 0s;
  background: rgb(250, 250, 250);
  box-shadow: rgb(141 158 255 / 2%) 0px -1px 1px, rgb(141 158 255 / 2%) 0px 4px 2px, rgb(141 158 255 / 4%) 0px 8px 4px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(25, 25, 46, 0.04);
  padding: 0px;
  margin: 0px;
}

.df-card-children{
  position: relative;
  transition: color 0s ease 0s, background 0s ease 0s, opacity 0s ease 0s, margin 0s ease 0s, padding 0s ease 0s, border-radius 0s ease 0s, all 0.2s cubic-bezier(0.16, 0.64, 0.48, 1) 0s;
  background: none center center / cover no-repeat;
  color: rgb(25, 25, 46);
  opacity: 1;
  box-shadow: none;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  padding: 32px;
  margin: 0px;
  gap: 0px;
}

.df-seperator{
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(204, 211, 254);
  flex-shrink: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  height: auto;
  min-height: 32px;
  opacity: 1;
}

.df-beta{
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 24px;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.16, 0.64, 0.48, 1) 0s;
  background: #dbccfe;
  user-select: none;
  padding: 4px;
  margin: 0px;
}

.df-beta-text{
  position: relative;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.16, 0.64, 0.48, 1) 0s;
  color: #2e2819;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: "salt", "zero", "liga", "ss01";
  font-weight: 400;
  margin: 0px;
  padding: 0px;
}

.ant-menu-item, .ant-menu-submenu, .ant-menu-submenu-title{
  opacity:0.9;
}

.ant-menu-item-selected, .ant-menu-submenu-selected span{
  font-weight: 500;
  color:#19192e;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-submenu {
  opacity:1;
	background: transparent;
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item span{
	background: transparent;
}

.ant-menu-item::after{
  border-right-color: #dbccfe!important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span{
	font-weight: 500;
  color:#19192e;
}

.ant-menu-item:hover, .ant-menu-submenu-title:hover, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
  color:#19192e!important;
}

.ant-menu-item a:hover, .ant-menu-submenu-title a:hover{
  color: #19192e;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: transparent;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span a{
	font-weight: 500;
  color:#19192e;
}

.ant-layout{
  background-color: #f4f4f5;
}