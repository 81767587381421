.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

p.language {
  text-transform: capitalize;
  text-decoration: dashed;
  text-decoration-line: underline;
  text-decoration-color: #7ec1ff;
  cursor: pointer;
}

p.defaultlanguage {
    text-transform: capitalize;
}

/* width */
::-webkit-scrollbar-track
{
  border-radius: 20px;
  background-color: #f3f3f3;
}

::-webkit-scrollbar
{
  width: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  border-radius: 20px;
  background-color: #9E9E9E;
}

/* The track NOT covered by the handle.
::-webkit-scrollbar-track-piece {
  background: #000; 
}

/* Corner */
::-webkit-scrollbar-corner {
  background: #999; 
}

/* Resizer */
/* ::-webkit-resizer {
  background: #111; 
} */