@keyframes from0to360 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

#Circle1 {
  fill: #ffc146;
  animation: from0to360 3s linear infinite;
  cx: 150;
  cy: 145;
  r: 100;
  transform-origin: 145px 150px;
}

@keyframes from360to0 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

#Circle2 {
  fill: #ffc146;
  animation: from360to0 5s linear infinite;
  cx: 150;
  cy: 155;
  r: 100;
  transform-origin: 155px 150px;
}

#Circle3 {
  fill: #ffc146;
  animation: from0to360 6s linear infinite;
  cx: 145;
  cy: 150;
  r: 100;
  transform-origin: 150px 145px;
}

#Circle4 {
  fill: #ffc146;
  animation: from360to0 5.5s linear infinite;
  cx: 155;
  cy: 150;
  r: 100;
  transform-origin: 150px 155px;
}

.container {
  /* // default by apple systems */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  margin: 0;
  padding: 20px;
  text-align: left;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
}
