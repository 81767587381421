.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

p.language {
  text-transform: capitalize;
  text-decoration: dashed;
  text-decoration-line: underline;
  text-decoration-color: #7ec1ff;
  cursor: pointer;
}

p.defaultlanguage {
    text-transform: capitalize;
}